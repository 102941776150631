(function($) {

	egc = (function() {
    return {

      	mobile_menu_toggle : function() {


      		toggle_navigation = '#btn--navigation-toggle';
      		site_navigation = '.navigation--primary';


      		$(toggle_navigation).on('click',function(e) {

          		e.preventDefault();
          		$(site_navigation).toggle();

       		});
      	},
      	form_validation: function() {

      		submit_button = '.btn--submit';

      			$(submit_button).click(function() {

        	});

      	},
	    events_list_more: function() {

	        postlist = ".post-list";
	        count = $(postlist).children().length;
	        more_posts = ".show-more--posts";
	        more_posts_button = ".show-more--posts__button";

	        if (count > 16) {
	            $(more_posts).show();
	            $('.post:gt(15)').hide();

	            $(more_posts_button).click(function() {

		         $('.post:gt(15)').show();
		         $(more_posts_button).hide();
		        });


	        } else {
	            $(more_posts).hide();

	        }
	    },
	    gallery_lightbox : function() {
	        $('.gallery').magnificPopup({
		        delegate: 'a',
		        type: 'image',
		        tLoading: 'Loading image #%curr%...',
		        mainClass: 'gallery__item',
		        gallery: {
		          enabled: true,
		          navigateByImgClick: true,
		          preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		        },
		        image: {
		          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
		          titleSrc: function(item) {
		            return item.el.attr('title');
		          }
		        }
		    });
	    },
      	events_list_filter : function() {

	      	// Default Post
	      	post = ".post--event";

	      	// Post Categories
	      	post_sustainability = ".sustainability";
	      	post_workplace = ".workplace-ethics";
	      	post_social = ".social-justice";

	      	// Filter Buttons
	      	filter_btn = '.filter-btn';
	        filter_btn_all = ".filter-btn--everything";
	        filter_btn_social = ".filter-btn--social-justice";
	        filter_btn_sustainability = ".filter-btn--sustainability";
	        filter_btn_workplace = ".filter-btn--workplace-ethics";


	        $(filter_btn_all).click(function() {
	        	$(filter_btn).removeClass('active');
	        	$(this).addClass('active');
	           	$(post).show();
	        });

	        $(filter_btn_social).click(function() {
	        	$(filter_btn).removeClass('active');
	        	$(this).addClass('active');
	        	$(post).hide();
	        	$(post_social).show();
			});

	        $(filter_btn_sustainability).click(function() {
	        	$(filter_btn).removeClass('active');
	        	$(this).addClass('active');
	        	$(post).hide();
	        	$(post_sustainability).show();
	        });

	        $(filter_btn_workplace).click(function() {
	        	$(filter_btn).removeClass('active');
	        	$(this).addClass('active');
	        	$(post).hide();
	        	$(post_workplace).show();
	        });

      	},
    };

	})();

  $(document).ready(function(jQuery) {

    egc.mobile_menu_toggle();
    egc.events_list_filter();
    egc.events_list_more();
   	egc.gallery_lightbox();

	$(".video-container").fitVids();

	function isEmpty( el ) {
		return !$.trim(el.html());
	}

	$('#accordion > h4, #accordion > div').each(function() {

		if (isEmpty($(this))) {
			$(this).remove();
		}

	});

	$('.accordion h4').click(function() {
		$(this).next().toggle('fast');
		return false;
	}).next().hide();

  });


})(jQuery);
